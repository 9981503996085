const Modal = {
  open: (content) => {
    const modal = document.querySelector('.js-modal')
    if (modal) {
      Modal.close(modal);
    }

    document.querySelector('main').insertAdjacentHTML('beforeend', content);
  },

  close: (element) => {
    element.closest('.js-modal').remove();
  }
}

export default Modal;
