class Certificates {
  static downloadCert() {
    const certElement = document.querySelector('.cert');
    const layout = certElement.getAttribute('data-layout');
    const isVertical = layout === 'vertical';

    html2canvas(certElement, {
      scale: 4,
      windowWidth: 1920,
      windowHeight: 1080,
      onclone: (clone) => {
        const content = clone.querySelector('.content');
        if (content) {
          content.style.marginTop = '-10px';
        }
        clone.querySelectorAll('.separator').forEach(el => el.style.margin = '10px 0 0 0');
      }
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF(isVertical ? 'portrait' : 'landscape', 'mm', 'a4');
      const width = isVertical ? 210 : 297;
      const height = isVertical ? 297 : 210;

      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('Certyfikat.pdf');
    });
  }
}

export default Certificates;