class Courses {

  static toggleSaveButtonVisibility() {
    const saveButton = document.querySelector('.js-save-course-button');
    if (!saveButton) { return }

    if (document.querySelector('.js-toggle-save-button-visibility')) {
        saveButton.classList.add('invisible');
    } else {
        saveButton.classList.remove('invisible');
    }
  }

  static saveLinksToLocalStorage() {
      var saveLinks = document.querySelectorAll('.save--link');

      saveLinks.forEach(function (link) {
          link.addEventListener('click', function (event) {
              event.preventDefault();

              var href = link.getAttribute('href');
              var savedLinks = JSON.parse(localStorage.getItem('savedLinks')) || [];

              if (!savedLinks.includes(href)) {
                  savedLinks.push(href);
                  localStorage.setItem('savedLinks', JSON.stringify(savedLinks));
              } 
          });
      });
  }

  static openSavedLessons() {
      var savedLinks = JSON.parse(localStorage.getItem('savedLinks')) || [];

      savedLinks.forEach(function (href) {
          var linksToClick = document.querySelectorAll('a[href="' + href + '"]');

          linksToClick.forEach(function (link) {
              link.click();
          });
      });
  }

  static correctQuizCheckboxes() {
      const selectors = document.querySelectorAll('.js-quiz-correct-selector');

      selectors.forEach(selector => {
          const checkboxes = selector.querySelectorAll('.js-correct-triger');

          checkboxes.forEach(checkbox => {
              checkbox.addEventListener('change', function() {
                  if (checkbox.checked) {
                      checkboxes.forEach(otherCheckbox => {
                          if (otherCheckbox !== checkbox) {
                              otherCheckbox.checked = false;
                          }
                      });
                  }
              });
          });
      });
  }

  static activeAnswerItem() {
      const answersList = document.getElementById('answers-list');
      const answerItems = answersList.getElementsByClassName('answer-item');
      const answerInput = document.getElementById('course_quiz_answer_user_answer_id');

      Array.from(answerItems).forEach(function(item) {
          item.addEventListener('click', function() {
              if (item.classList.contains('answer-active')) {
                  item.classList.remove('answer-active');
                  answerInput.value = "";
              } else {
                  Array.from(answerItems).forEach(function(i) {
                      i.classList.remove('answer-active');
                  });
                  item.classList.add('answer-active');

                  const answerId = item.getAttribute('data-answer-id');

                  if (answerInput) {
                      answerInput.value = answerId;
                  }
              }
          });
      });
  }
}

export default Courses;