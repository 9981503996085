import { Controller } from 'stimulus';
import tinymce from 'tinymce/tinymce';

export default class extends Controller {
    static targets = ['editor', 'addButton', 'form'];

    connect() {
        this.checkContent();
    }

    checkContent() {
        const content = document.getElementById('course_note_user_content').value.trim();
        if (content.length > 0) {
            this.showEditor();
        } else {
            this.showAddButton();
        }
    }

    showEditor() {
        this.editorTarget.classList.remove('hidden');
        this.addButtonTarget.classList.add('hidden');
    }

    showAddButton() {
        this.editorTarget.classList.add('hidden');
        this.addButtonTarget.classList.remove('hidden');
    }

    toggleEditor() {
        this.showEditor();
    }
}
