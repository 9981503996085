// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
Rails.start();

import "@hotwired/turbo-rails";
Turbo.session.drive = false;

require("turbolinks").start()
require("@rails/activestorage").start()
require("@rails/actiontext")
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("alpinejs")
require("@eastdesire/jscolor")
require("tinymce")

import "stylesheets/application"
import "src/service_worker.js"

import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"
import videojs from 'video.js'
import $ from "jquery"
import Sortable from 'sortablejs';
import Modal from './modal';
import Notifications from "./notifications";
import Utilities from './utilities';
import Certificates from './certificates';
import Courses from './courses';
import VimeoPlayerManager from './vimeo_player_manager';
import cocoonVanilaJs from '@kollegorna/cocoon-vanilla-js';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

require('videojs-playlist')
require('videojs-playlist-ui')
require('videojs-seek-buttons')
require('@silvermine/videojs-chromecast')(videojs)
require('@silvermine/videojs-airplay')(videojs)

const SKIP_TIME = 30;

const pl = require('video.js/dist/lang/pl.json')
videojs.addLanguage('pl', pl)
videojs.addLanguage('pl', {
    "Now Playing": "Teraz odtwarzane",
    "Up Next": "Następny rozdział",
    "Untitled Video": "Rozdział bez nazwy",
    "Seek forward {{seconds}} seconds": "Przewiń {{seconds}} sekund",
    "Seek back {{seconds}} seconds": "Cofnij {{seconds}} sekund",
    "Open Chromecast menu": "Otwórz menu Chromecast"
});

window.Sortable = Sortable;
window.Modal = Modal;
window.Notifications = Notifications;
window.Utilities = Utilities;
window.Certificates = Certificates;
window.Courses = Courses;
window.VimeoPlayerManager = VimeoPlayerManager;
window.Videojs = videojs;
window.html2canvas = html2canvas;
window.jsPDF = jsPDF;

$(document).ajaxError(function (event, jqxhr, settings, exception) {
    if (jqxhr.status == 401) {
        window.location.reload();
    }
});

function checkDashboardCard() {
    let cards = document.getElementsByClassName('dashboard-card')
    if (cards.length == 0) return;

    cards[0].checked = true
}

document.addEventListener("turbolinks:load", function () {
    checkDashboardCard();
});

document.addEventListener("turbolinks:before-visit", function () {
    disposePlayers();
});

document.addEventListener("turbolinks:before-cache", function () {
    disposePlayers();
});

function disposePlayers() {
    let playerElement = document.querySelector('#player');
    let videoPlayerElement = document.querySelector('#video_player');

    if (playerElement) videojs(playerElement).dispose();
    if (videoPlayerElement) videojs(videoPlayerElement).dispose();
}

function reportProgress(url, id, progress, async = true) {
    $.ajax({
        url: url,
        type: "POST",
        async: async,
        data: { id: id, progress: progress },
    });
}

function updateMediaSession(currentItem) {
    let curSrc = Object.assign({}, currentItem);
    curSrc.title = curSrc.name;
    navigator.mediaSession.metadata = new MediaMetadata(curSrc);
    navigator.mediaSession.setPositionState({
        duration: curSrc.duration,
        position: 0,
    });
}

function setUpMediaSession(player) {
    let audioPlaceholder = document.getElementById('audio-placeholder');

    navigator.mediaSession.setActionHandler('previoustrack', function () {
        player.playlist.previous();
    });
    navigator.mediaSession.setActionHandler('nexttrack', function (details) {
        player.playlist.next();
    });
    navigator.mediaSession.setActionHandler('play', function () {
        player.play();
    });
    navigator.mediaSession.setActionHandler('pause', function () {
        player.pause();
    });
    navigator.mediaSession.setActionHandler('seekto', function (details) {
        player.currentTime(details.seekTime);
    });
    navigator.mediaSession.setActionHandler('seekbackward', (details) => {
        const skipTime = details.seekOffset || SKIP_TIME;
        player.currentTime(Math.max(player.currentTime() - skipTime, 0));
    });
    player.on('playlistitem', function (event, item) {
        audioPlaceholder.play();
        updateMediaSession(item);
    });
}

document.addEventListener("turbolinks:load", function () {
    if ($('#player').length) {
        let player = videojs('player', {
            techOrder: ['chromecast', 'html5'],
            aspectRatio: "1:0",
            fluid: true,
            controlBar: { fullscreenToggle: false, pictureInPictureToggle: false },
            html5: { vhs: { withCredentials: document.body.dataset.credentials === 'true' } },
            language: 'pl',
            playbackRates: [0.75, 1, 1.15, 1.25, 1.5, 2],
            plugins: {
                chromecast: {},
                airPlay: {}
            },
            textTrackSettings: false
        });

        let savedPosition = $('#player').data('savedPosition');
        let savedTime = $('#player').data('savedTime');
        let chapters = $('#player').data('source');
        let artwork = $('#player').data('artwork');
        let album = $('#player').data('album');
        let sources = chapters.map(chapter =>
            ({
                name: chapter.title,
                duration: chapter.duration,
                album: album,
                artwork: [{
                    src: artwork
                }],
                sources: [{
                    chapter_id: chapter.chapter_id,
                    src: chapter.s3_url,
                    type: 'application/x-mpegURL'
                }]
            })
        )

        player.setInterval(function () {
            if (player.paused()) {
                return;
            }
            reportProgress("/chapters/progress", player.currentSource().chapter_id, player.currentTime());
        }, 10000);

        player.setInterval(function () {
            if (!player.paused()) {
                return;
            }
            reportProgress("/chapters/progress", player.currentSource().chapter_id, player.currentTime());
        }, 55000);

        var playrate;

        player.on('beforeplaylistitem', (event, item) => {
            reportProgress("/chapters/progress", item.sources[0].chapter_id, (savedTime || 0), false);
            savedTime = null;
            playrate = player.playbackRate();
        })

        player.on('playlistitem', function () {
            player.playbackRate(playrate);
        });

        player.seekButtons({
            back: SKIP_TIME,
            backIndex: 2
        });

        let currentTime = savedTime;
        player.playlist(sources, savedPosition);
        player.playlist.autoadvance(0);
        player.currentTime(currentTime);
        player.playlistUi({playOnSelect: true});

        if ('mediaSession' in navigator) {
            setUpMediaSession(player);
        }

        player.ready(function(){
            this.volume(0.4);
        });
    }

    if ($('#video_player').length) {
        let player = videojs('video_player', {
            techOrder: ['chromecast', 'html5'],
            fluid: true,
            html5: { vhs: { withCredentials: document.body.dataset.credentials === 'true' } },
            playbackRates: [0.75, 1, 1.15, 1.25, 1.5, 2],
            language: 'pl',
            height: 1080,
            plugins: {
                chromecast: {},
                airPlay: {}
            },
            textTrackSettings: false
        });

        let savedPosition = $('#video_player').data('savedPosition');
        let savedTime = $('#video_player').data('savedTime');
        let episodes = $('#video_player').data('source');

        let sources = episodes.map(episode =>
          ({
              name: episode.title,
              description: episode.description,
              duration: episode.duration,
              album: 'album',
              artwork: [{
                  src: episode.cover_photo
              }],
              sources: [{
                  episode_id: episode.episode_id,
                  src: episode.s3_url,
                  type: 'application/x-mpegURL'
              }]
          })
        )

        player.setInterval(function () {
            if (player.paused()) {
                return;
            }
            reportProgress("/episodes/progress", player.currentSource().episode_id, player.currentTime());
        }, 10000);

        player.setInterval(function () {
            if (!player.paused()) {
                return;
            }
            reportProgress("/episodes/progress", player.currentSource().episode_id, player.currentTime());
        }, 55000);

        var playrate;

        player.on('beforeplaylistitem', (event, item) => {
            $('#episode_title').text(item.name)
            $('#episode_description').html(item.description)
            reportProgress("/episodes/progress", item.sources[0].episode_id, (savedTime || 0), false);
            savedTime = null;
            playrate = player.playbackRate();
        })

        player.on('playlistitem', function () {
            player.playbackRate(playrate);
        });

        player.seekButtons({
            back: SKIP_TIME,
            backIndex: 2
        });

        let currentTime = savedTime;
        player.playlist(sources, savedPosition);
        player.playlist.autoadvance(0);
        player.currentTime(currentTime);
        player.playlistUi({playOnSelect: true});

        player.ready(function(){
            this.volume(0.4);
        });
    }

    if (document.querySelectorAll('.save--link').length) {
        Courses.saveLinksToLocalStorage();
        Courses.openSavedLessons();
    }

    if ($('#course-aside').length && $(window).width() > 1000) {
        var courseAsideHeight = $('#course-aside').height();
        var courseContentHeight = $('#course-content').height();

        if (courseContentHeight > courseAsideHeight) {
            $('#course-aside').removeClass('max-h-screen overflow-y-auto');
        }
    }
})

document.addEventListener('turbo:before-stream-render', (event) => {
    Notifications.removeTurboNotifications();
});

document.addEventListener('turbolinks:load', function () {
    Utilities.inputFile();
    Utilities.inputImage();
    Utilities.initCocoon();
    Utilities.initSortable();
    Utilities.handleInputSubmit();
    Utilities.setDefaultVisibility();
    Utilities.triggerToggleVisibility();
    Utilities.copyToClipboard();
    Utilities.serializeForm();
    Utilities.directFileUpload();
    Utilities.initJsColor();
    Utilities.submitOnFileUpload();
    Courses.toggleSaveButtonVisibility();
    VimeoPlayerManager.vimeoPlayer();
})

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
