class Utilities {

    static inputFile() {
        this.handleEvent('.js-file-input', 'change', function () {
          this.closest('.js-file-fields').querySelector('.js-filename').innerHTML = this.files[0].name;
        });
    }

    static inputImage() {
        this.handleEvent('.js-image-input', 'change', function () {
          const imageFile = this.files[0];
          const img = `<img src="${window.URL.createObjectURL(imageFile)}" />`;
          document.querySelector('.js-image-label').innerHTML = img;
          document.querySelector('.js-image-filename').innerHTML = imageFile.name;
        });
    }

    static initCourseTinymce(linksForSelect) {
      const pathsForSelect = this.parseValue(linksForSelect);
      this.initTinymce('.tinymce', {
          link_list: pathsForSelect,
          relative_urls: false,
          remove_script_host: true,
          document_base_url: "/"
      });
    }

    static initTinymce(selector = '.tinymce', options = {}) {
      tinymce.remove(selector);

      const defaultOptions = TinyMCERails.configuration.default;
      const mergedOptions = { ...defaultOptions, ...options, selector };

      try {
        tinymce.init(mergedOptions);
      } catch (error) {
        console.error('Failed to initialize TinyMCE:', error);
      }
    }

    static initCocoon() {
      $('.cocon-node').data('association-insertion-method', 'append').data('association-insertion-node', 'this');
    }

    static handleEvent(identifier, eventType, callback, root = document) {
        const elements = root.querySelectorAll(identifier);
        if (elements.length === 0) return false;

        elements.forEach(function (elem) {
          elem.removeEventListener(eventType, callback);
          elem.addEventListener(eventType, callback);
        });
    }

    static initJsColor() {
      jscolor.install();
    }

    static initSortable() {

      const lessonList = document.getElementById('lesson-list');
      if (!lessonList) return;

      lessonList.querySelectorAll('.js-sortable').forEach(function(sortableList) {
        new Sortable(sortableList, {
          handle: '.js-handler',
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          onEnd: function (event) {
            Utilities.requestForSort(event)
          }
        });

        sortableList.querySelectorAll('.js-handler').forEach(function(handler) {
          handler.addEventListener('click', (event) => {
            event.preventDefault();
          });
        });
      });
    }

    static requestForSort(event) {
      const items = Array.from(event.target.querySelectorAll(':scope > .js-item-sortable'));
      const url = event.target.dataset.url
      const data = {}

      items.forEach((item, index) => { data[item.dataset.id] = index } );

      $.ajax({
        url: url,
        type: 'PATCH',
        beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        options.data = JSON.stringify({ positions: data })
        return true
        }
      });
    }

    static handleInputSubmit() {
      let timeout;
      this.handleEvent('.js-input-submit', 'keyup', function () {
        const form = this.closest('form');
        clearTimeout(timeout);
        timeout = setTimeout(() => form.submit(), 500);
      });
    }

    static setDefaultVisibility()  {
      const trigger = document.querySelector('.js-visibility-trigger')
      const targets = document.querySelectorAll('.js-visibility-target')

      Utilities.checkboxToggleVisibility(trigger, targets);
    }

    static triggerToggleVisibility() {
      this.handleEvent('.js-visibility-trigger', 'change', function () {
        Utilities.checkboxToggleVisibility(this, document.querySelectorAll('.js-visibility-target'))
      });
    }

    static checkboxToggleVisibility(checkboxTrigger, targets) {
      if (!checkboxTrigger || !targets ) return

      if (checkboxTrigger.checked) {
        targets.forEach(target => {
          target.classList.remove('invisible', 'h-0', 'w-0');
        });
        } else {
        targets.forEach(target => {
          target.classList.add('invisible', 'h-0', 'w-0');
        });
      }
    }

    static parseValue(strData) {
      try {
          return JSON.parse(strData);;
      } catch (e) {
          return {};
      }
    }

    static copyToClipboard() {
      const elements = document.querySelectorAll('[data-clipboard-text]');

      elements.forEach((element) => {
        element.addEventListener('click', () => {
          const textToCopy = element.dataset.clipboardText;
          navigator.clipboard.writeText(textToCopy)
        });
      });
    }

    static serializeForm() {
      this.handleEvent('.js-serialize-button', 'click', function (event) {

          const formId = this.getAttribute('data-form-id');
          const form = document.getElementById(formId);
          const url = this.getAttribute('data-url');
          const method = this.getAttribute('data-method');
          const additionalParams = JSON.parse(this.getAttribute('data-params') || "{}");

          if (!form || !url || !method) return;

          const formData = new FormData(form);

          for(let key in additionalParams) {
            formData.append(key, additionalParams[key]);
          }

          Utilities.makeAjaxRequest(url, method, formData);
      });
    }

    static directFileUpload() {
      this.handleEvent('.js-direct-file-upload', 'change', function (event) {

        const form = this.form;
        const submitButtons = form.querySelectorAll('button[type="submit"], input[type="submit"]');

        Utilities.toggleButtons(submitButtons, true)

        const file = this.files[0]
        const url = this.getAttribute('data-url');
        const params = this.getAttribute('data-params');

        if (!file || !url) {
          Utilities.toggleButtons(submitButtons, false)
          return;
        }

        const formData = new FormData(form);
        formData.append('direct_file_upload[file]', file);

        if (params) {
          const parsedParams = JSON.parse(params);

          for (let key in parsedParams) {
            formData.append(key, parsedParams[key]);
          }
        }
        Utilities.makeAjaxRequest(url, 'POST', formData);
      })
    }

    static toggleButtons(buttons, disabled) {
      buttons.forEach(button => button.disabled = disabled);
    }
    
    static makeAjaxRequest(url, method, formData) {
      $.ajax({
        url: url,
        type: 'POST',
        beforeSend(xhr, options) {
          options.data = formData;
          return true;
        }
      });
    }

   static submitOnFileUpload() {
      this.handleEvent('.js-submit-on-file-input', 'change', function () {
        const fileInput = this;
        const form = fileInput.closest('form');

        if (fileInput.files.length > 0) {
          const formData = new FormData(form);
          const url = form.action;

          $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'script',
            headers: {
              'Accept': 'text/javascript'
            }
          });
        }
      });
    }

    static 
}

export default Utilities;
