const Notifications = {

  close: () => {
    document.querySelector('.js-notifications').remove();
  },

  open: (partial) => {
    document.querySelector('#notifications').insertAdjacentHTML('beforeend', partial);

    setTimeout(function(){
      Notifications.close();
    }, 5000);
  },

  removeTurboNotifications: () => {
    setTimeout(() => {
      const flashMessages = document.querySelectorAll(".notification-item");
      flashMessages.forEach(flashMessage => {
        flashMessage.remove();
      });
    }, 3000);
  }
}

export default Notifications;
