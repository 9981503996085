class VimeoPlayerManager {

    static vimeoPlayer() {
        var iframe = document.querySelector('iframe');
        var player = new Vimeo.Player(iframe);

        function setPlaybackRateFromCache() {
            var savedPlaybackRate = localStorage.getItem('vimeoPlaybackRate');
            if (savedPlaybackRate) {
                player.setPlaybackRate(parseFloat(savedPlaybackRate)).then(function(rate) {

                }).catch(function(error) {
                    console.error('Error when set playback rate:', error);
                });
            }
        }

        function savePlaybackRateToCache(rate) {
            localStorage.setItem('vimeoPlaybackRate', rate);
        }

        if (iframe && iframe.src.includes('player.vimeo.com')) {
            setPlaybackRateFromCache();

            player.on('playbackratechange', function(event) {
                savePlaybackRateToCache(event.playbackRate);
            });
        }
    }

}

export default VimeoPlayerManager;